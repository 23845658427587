var onnit_product_page = {
    change_hero_image: function (pid) {

        if (String(pid).match(/^sizing/)) {
            pid = _pids.sizing.override;
        }

        var unisex_opt = '';
        if (window.location.hash) {
            if (window.location.hash === '#womens') {
                unisex_opt = '-womens';
            }
        }

        var _w = 735;

        $('#product-page .hero-image .hero-image-main, .product-slide-thumb.is-hero img')
            .attr('data-src', ONNIT.Authority(pid, { 'extension': unisex_opt, 'w': _w, 'full': true }))
            .attr('src', ONNIT.Authority(pid, { 'extension': unisex_opt, 'w': _w, 'full': true }));
    }
    , hide_360_video: function () {
        var $overlay360 = $('#product-page .hero-image .overlay.v360'),
            $video = $('#product-page .hero-360-video');
        if ($overlay360.length && $video.length) {
            $overlay360.removeClass('active');
            $video.remove();
            $('#hero-360-video-close').remove();
            $('#product-page .hero-image-main').css('opacity', 1);
        }
    }
    , show_360_video: function (pid) {
        if (!$('.hero-360-video').length) {

            var $video =
                '<video class="hero-360-video" preload="non" autoplay loop width="439" height="484" poster="data:image/gif,AAAA">'
                + '<source src="https://onnit.s3.amazonaws.com/site-videos/360-views/' + pid + '.mp4" type="video/mp4" />'
                + '<source src="https://onnit.s3.amazonaws.com/site-videos/360-views/' + pid + '.webm" type="video/webm" />'
                + '</video>'
                + '<button title="Close" type="button" class="mfp-close" id="hero-360-video-close" style="color:#333;outline:none;">×</button>';

            $('#product-page .hero-image').append($video);
            $('#product-page .hero-360-video')[0].play();
            $('#product-page .hero-360-video').click(function (e) {
                e.preventDefault();
                var video = $(this)[0];
                if (video.paused === false) {
                    video.pause();
                } else {
                    video.play();
                }
            });
            $('#hero-360-video-close').click(function (e) {
                e.preventDefault();
                onnit_product_page.hide_360_video();
            });
            $('#product-page .hero-image-main').css('opacity', 0);
        }
    }
};



$(function () {

    // product slides
    if ($('.product-slider').length) {
        $('.product-slider').on('init', function (e, slick) {
            var label = 'product-image-gallery-label';
            if ($('#' + label).length) {
                $('.slick-track').attr('aria-labelledby', 'product-image-gallery-label');
            }
            setTimeout(function () {
                // Fix lazyloading of product slider thumbnails
                window.doLazyReval && window.doLazyReval();
            }, 50);
        });
        $('.product-slider').on('reInit', function (e, slick) {
            setTimeout(function () {
                // Fix lazyloading of product slider thumbnails
                window.doLazyReval && window.doLazyReval();
            }, 50);
        });
        var $product_slider = $('.product-slider').slick({
            accessibility: true,
            focusOnChange: true,
            fade: true,
            speed: 300
        });

        $('.product-slider-thumb').click(function () {
            $product_slider.slick('slickGoTo', ($(this).index() * $product_slider.slideCount));
        });

        $('.product-slide-thumbs').on('click', '.product-slide-thumb', function () {
            var i = $('.product-slide-thumbs .product-slide-thumb').index(this);
            $('.product-slider').slick('slickGoTo', i);
        });

        // Remove incorrect use of aria-describedby
        $('.slick-slide','.slick-slider').each(function (i, elem) {
            $(elem).removeAttr('aria-describedby');
        });

    }

    // is there an apparel video?
    var apparel_video = null;

    if ($('#product-page').hasClass('apparel-template-2018')
        || $('#product-page').hasClass('is-stephanie-page')) {
        var $apparel_slider = $('.apparel-slider-2018').slick({
            fade: true,
            speed: 300,
            arrows: false,
            dots: true,
            mobileFirst: true,
            responsive: [
                {
                    breakpoint: 740,
                    settings: {
                        dots: false
                    }
                }
            ]
        });

        $apparel_slider.on('beforeChange', function (event, slick, currentSlide, nextSlide) {
            if (apparel_video) {
                apparel_video.pause();
            }
        });

        $('.apparel-slider-thumbs').on('click', '.apparel-slider-thumb', function () {
            $('.apparel-slider-thumb').removeClass('current');
            $(this).addClass('current');
            var target = $(this).attr('data-target-slide');
            var $targetObj = $('.apparel-slider-2018').find('.slick-slide[data-anchor-slide="' + target + '"]');
            var slide_match = parseInt($targetObj.attr('data-slick-index'));
            $apparel_slider.slick('slickGoTo', slide_match);

            if (($targetObj).hasClass('video')) {
                apparel_video = $targetObj.find('video')[0];
                apparel_video.play();
            }
        });
    }

    if (navigator.userAgent.match(/android/i)) $('html').addClass('android');

    // youtube placeholders
    $('.lazyYT').lazyYT(lazyYT_api_key);
});

$(function () {
    // @neil oct 2017: lazyload images
    var bLazy = new Blazy({
        offset: 1600,
        breakpoints: [{
            width: 739, // max-width
            src: 'data-src-small'
        }]
    });

    // check if lazy loaded div backgrounds need to be loaded for another breakpoint
    window.doLazyReval = function () {
        bLazy.revalidate();

        // once everything's been loaded, stop checking
        if (!$('.b-lazy:not(.b-loaded)').length) {
            $(window).off('resize', doLazyReval);
        }
    };

    $(window).on('resize', doLazyReval);

    // @neil march 2016: initialize pro recommendations
    if (typeof pro_rec === 'object') {
        pro_rec.init();
    }

    // @neil june 2015: add product videos to product page inline
    $('.js-product-video-items').on('click', 'a', function (e) {
        e.preventDefault();
        var $el = $(this);
        $('.js-product-video-items a').removeClass('active');
        $(this).addClass('active');
        $(".pp-player")
            .empty()
            .html(
                '<div class="lazyYT" data-title="Video" data-youtube-id="' +
                    $el.data("video-id") +
                    '" data-ratio="16:9" data-parameters="rel=0">loading...</div>'
            )
            .find(".lazyYT")
            .lazyYT(lazyYT_api_key);
    });


    // @neil june 2015: 360 product rotation videos
    $('#product-page .hero-image .overlay').click(function () {
        var $el = $(this),
            pid = $('.main-select').val();

        if ($el.hasClass('v360')) {
            $el.toggleClass('active');
            if ($el.hasClass('active')) {
                onnit_product_page.show_360_video(pid);
            } else {
                onnit_product_page.hide_360_video();
            }
        }
    });

    // @neil august 2016: mini sections of content for apparel pages
    $('.apparel-minisection.is-expandable .apparel-minisection-title').click(function () {
        $(this).parents('.apparel-minisection').toggleClass('is-expanded');
    });

    // table of contents
    $('.product-toc-expand-trigger').click(function () {
        $('.product-toc-expandable').slideToggle().toggleClass('is-expanded');
        var caption = ($('.product-toc-expandable').hasClass('is-expanded'))
            ? 'less&thinsp;&hellip;'
            : 'more&thinsp;&hellip;';
        $('.product-toc-expand-trigger').html(caption);
    });

    // @neil december 2016: can't remember if this is important?
    if ($('#product-page').hasClass('has-teaser')) {
        $('html').addClass('has-teaser');
    }
});

// Multiple SFP selector dropdown
window.addEventListener('DOMContentLoaded', function () {
    var sfpDataDiv = document.getElementById('sfpData');

    if (typeof(sfpDataDiv) != 'undefined' && sfpDataDiv != null) {

        document.getElementById('SFPMultiSelector').style.display = 'block';

            // This must have a timeout of 300 so it gives time to the buy area selector to load
            setTimeout(function () {

                var buyAreaSelector = document.getElementById('oui-form-field-id-1');

                if(buyAreaSelector) {
                    buyAreaSelector.addEventListener('change', function (event) {
                        getImageBasedOnPid(this.value);
                    });
                }
            }, 300);

            var defaultPid = typeof (_auto_pid) === 'undefined' ? null : _auto_pid;
            getImageBasedOnPid(defaultPid);
        }
});

function getImageBasedOnPid(productId = null)
{
    var sfpData = JSON.parse(document.getElementById('sfpData').textContent);

    var imageData = sfpData.imageData;

    var sfpDropdown = document.getElementById('SFPDropdown');

    if (productId) {
        sfpDropdown.value = productId;
    }

    var currentProductId = parseInt(sfpDropdown.value);

    var SFPImageHTML = document.getElementById('SFPImage');

    var imageToUse = Object.values(imageData).find((image) => {
        return currentProductId === image.productId;
    });

    SFPImageHTML.dataset.src = `https://onnits3.imgix.net/images/product-page/info/${imageToUse.name}.${imageToUse.extension}?q=40&auto=compress,format`;
    SFPImageHTML.src = SFPImageHTML.dataset.src;

    return SFPImageHTML;
}



